import { CustomerUserDef } from "@g3p/customer/interfaces/customer-def.interface";

export interface UserResetMfaState {
    user: CustomerUserDef;
    busy: boolean;
}

export const initialUserResetMfaState: UserResetMfaState = {
    user: null,
    busy: false
}