import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserResetMfaModalFacade } from './store/user-reset-mfa-modal.facade';

@Component({
  selector: 'g3p-user-reset-mfa-modal',
  templateUrl: './user-reset-mfa-modal.component.html',
  styleUrls: ['./user-reset-mfa-modal.component.scss']
})
export class UserResetMfaModalComponent implements OnDestroy {

  private alive = true;
  message: string = null;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private translateService: TranslateService,
    private facade: UserResetMfaModalFacade
  ) {
    this.createMessage();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  confirmDialog() {
    this.facade.resetMfa(this.data.user);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  private createMessage() {
    const userInfo = {
      username: this.data.user.username,
      firstname: this.data.user.firstName,
      lastname: this.data.user.lastName
    };
    this.message = this.translateService.instant('content.user-reset-mfa-confirm', userInfo);
  }

}
