import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import * as fromActions from './user-reset-mfa-modal.actions';
import { UserResetMfaState } from './user-reset-mfa-modal.state';
import { isBusy } from './user-reset-mfa-modal.selectors';
import { Injectable } from '@angular/core';
import { CustomerUserDto } from '@g3p/customer/interfaces/customer-dto.interface';
import { CustomerUserDef } from '@g3p/customer/interfaces/customer-def.interface';

@Injectable({ providedIn: 'root' })
export class UserResetMfaModalFacade {
  constructor(private store$: Store<UserResetMfaState>) {}

  openModal(user: CustomerUserDef) {
    this.store$.dispatch(new fromActions.OpenUserResetMfaDialog(user));
  }

  resetMfa(user: CustomerUserDef) {
    this.store$.dispatch(new fromActions.UserResetMfaRequest(user));
  }

  isBusy$(): Observable<boolean> {
    return this.store$.select(isBusy);
  }
}
