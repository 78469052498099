import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MAT_DATE_LOCALE } from "@angular/material/core";

import { EffectsModule } from '@ngrx/effects';

import { TranslateModule } from '@ngx-translate/core';

import { G3ComponentLibModule } from '@cgm/g3-component-lib';

import { CustomerCreateEffects } from '@g3p/customer/components/customer-create-modal/store/customer-create.effects';
import { CustomerDetailsEffects } from '@g3p/customer/components/customer-details/store/customer-details.effects';
import { CustomerListEffects } from '@g3p/customer/components/customer-list/store/customer-list.effects';
import { CustomerRoutingModule } from '@g3p/customer/customer.routing';
import { MasonryGridDirective } from '@g3p/shared/directives/masonry-grid/masonry-grid.directive';
import { PasswordRevealDirective } from '@g3p/shared/directives/password-reveal/password-reveal.directive';
import { SharedModule } from '@g3p/shared/shared.module';

import { AccountStepComponent } from './components/customer-create-modal/components/account-step/account-step.component';
import { AccountUsageModalComponent } from './components/customer-create-modal/components/account-usage-modal/account-usage-modal.component';
import { CustomerDataStepComponent } from './components/customer-create-modal/components/customer-data-step/customer-data-step.component';
import { WorkstationStepComponent } from './components/customer-create-modal/components/workstation-step/workstation-step.component';
import { CustomerCreateModalComponent } from './components/customer-create-modal/customer-create-modal.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { CustomerListFilterModalComponent } from './components/customer-list-filter-modal/customer-list-filter-modal.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { CustomerComponent } from './customer.component';
import { WorkstationToggleActiveModalComponent } from './components/workstation-toggle-active-modal/workstation-toggle-active-modal.component';
import { CustomerUserStatusModalEffects } from './components/customer-user-status-modal/store/customer-user-status-modal.effects';
import { CustomerUserStatusModalComponent } from './components/customer-user-status-modal/customer-user-status-modal.component';
import { CustomerDeletionModalComponent } from "./components/customer-deletion-modal/customer-deletion-modal.component";
import { CustomerDeletionStateModalComponent } from '@g3p/customer/components/customer-deletion-state-modal/customer-deletion-state-modal.component';
import {DocumentEffects} from "@g3p/customer/store/document/document.effects";
import { UserResetMfaModalComponent } from './components/user-reset-mfa-modal/user-reset-mfa-modal.component';
import { UserResetMfaModalEffects } from './components/user-reset-mfa-modal/store/user-reset-mfa-modal.effects';

@NgModule({
  declarations: [
    CustomerComponent,
    CustomerListComponent,
    CustomerListFilterModalComponent,
    CustomerDetailsComponent,
    CustomerCreateModalComponent,
    CustomerUserStatusModalComponent,
    AccountStepComponent,
    CustomerDataStepComponent,
    WorkstationStepComponent,
    MasonryGridDirective,
    PasswordRevealDirective,
    AccountUsageModalComponent,
    WorkstationToggleActiveModalComponent,
    CustomerDeletionModalComponent,
    CustomerDeletionStateModalComponent,
    UserResetMfaModalComponent
  ],
  imports: [
    CommonModule,
    G3ComponentLibModule,
    CustomerRoutingModule,
    EffectsModule.forFeature([CustomerCreateEffects, CustomerListEffects, CustomerDetailsEffects, CustomerUserStatusModalEffects, DocumentEffects, UserResetMfaModalEffects]),
    TranslateModule,
    SharedModule,
    FormsModule,
    CdkAccordionModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' }]
})
export class CustomerModule {}
