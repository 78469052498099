import { CustomerUserDef } from "@g3p/customer/interfaces/customer-def.interface";
import { Action } from "@ngrx/store";

export enum UserResetMfaActionTypes {
    USER_RESET_MFA_REQUEST = '[Cashbook - Admin page] User Reset Mfa - Request',
    USER_RESET_MFA_SUCCESS = '[Cashbook - Admin page] User Reset Mfa - Success',
    USER_RESET_MFA_FAILURE = '[Cashbook - Admin page] User Reset Mfa - Failure',
  
    OPEN_USER_RESET_MFA_DIALOG = '[Cashbook - Admin page] Open user reset Mfa dialog',
    CLOSE_USER_RESET_MFA_DIALOG = '[Cashbook - Admin page] Close user reset Mfa dialog'
  }

export class UserResetMfaRequest implements Action {
  readonly type = UserResetMfaActionTypes.USER_RESET_MFA_REQUEST;
  constructor(public user: CustomerUserDef) {}
}

export class UserResetMfaSuccess implements Action {
  readonly type = UserResetMfaActionTypes.USER_RESET_MFA_SUCCESS;
  constructor(public user: CustomerUserDef) {}
}

export class UserResetMfaFailure implements Action {
  readonly type = UserResetMfaActionTypes.USER_RESET_MFA_FAILURE;
  constructor(public user: CustomerUserDef) {}
}

export class OpenUserResetMfaDialog implements Action {
  readonly type = UserResetMfaActionTypes.OPEN_USER_RESET_MFA_DIALOG;
  constructor(public user: CustomerUserDef) {}
}

export class CloseUserResetMfaDialog implements Action {
  readonly type = UserResetMfaActionTypes.CLOSE_USER_RESET_MFA_DIALOG;
}

export type UserResetMfaActions =
  | UserResetMfaRequest
  | UserResetMfaSuccess
  | UserResetMfaFailure
  | OpenUserResetMfaDialog
  | CloseUserResetMfaDialog