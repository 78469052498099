import cloneDeep from 'lodash/cloneDeep';
import { newState } from '@g3p/shared/helpers/app-helpers';
import { UserResetMfaActions, UserResetMfaActionTypes } from './user-reset-mfa-modal.actions';
import { initialUserResetMfaState, UserResetMfaState } from "./user-reset-mfa-modal.state";

export function userResetMfaReducers(
    state: UserResetMfaState = cloneDeep(initialUserResetMfaState),
    action: UserResetMfaActions
) {
    switch (action.type) {
        case UserResetMfaActionTypes.USER_RESET_MFA_REQUEST:
            return newState(state, (state.busy = true));

        case UserResetMfaActionTypes.USER_RESET_MFA_SUCCESS:
            return newState(state, (state.busy = false, state.user.hasMfa = false));

        case UserResetMfaActionTypes.USER_RESET_MFA_FAILURE:
            return newState(state, (state.busy = false));
            
        case UserResetMfaActionTypes.OPEN_USER_RESET_MFA_DIALOG:
            return newState(state, (state.user = action.user))
    }
}