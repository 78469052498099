import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { TranslateService } from '@ngx-translate/core';

import { G3SnackbarService, G3SnackType } from '@cgm/g3-component-lib';

import { UserResetMfaModalService } from '../services/user-reset-mfa-modal.service';
import * as fromActions from './user-reset-mfa-modal.actions';
import { UserResetMfaModalComponent } from '../user-reset-mfa-modal.component';
import { CustomerUserDef } from '@g3p/customer/interfaces/customer-def.interface';

@Injectable({ providedIn: 'root' })
export class UserResetMfaModalEffects {
  resetUserMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UserResetMfaActionTypes.USER_RESET_MFA_REQUEST),
      switchMap((action: fromActions.UserResetMfaRequest) =>
        this.service.resetUserMfa(action.user.id).pipe(
          map(() => new fromActions.UserResetMfaSuccess(action.user)),
          catchError(() => of(new fromActions.UserResetMfaFailure(action.user)))
        )
      )
    )
  );

  updateCustomerUserStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UserResetMfaActionTypes.USER_RESET_MFA_SUCCESS),
      switchMap((action: any) => {
        let message = 'content.user-reset-mfa-success';
        this.snackbarService.open(
          this.translateService.instant(message, {username: action.user.username}),
          7000,
          G3SnackType.Success
        );
        return of(new fromActions.CloseUserResetMfaDialog());
      })
    )
  );

  updateCustomerUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.UserResetMfaActionTypes.USER_RESET_MFA_FAILURE),
        tap((action: any) => {
          let message = 'content.user-reset-mfa-failure';
          this.snackbarService.open(
            this.translateService.instant(message, action.user.username),
            7000,
            G3SnackType.Error
          );
        })
      ),
    { dispatch: false }
  );

  openModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.UserResetMfaActionTypes.OPEN_USER_RESET_MFA_DIALOG),
        tap((user: CustomerUserDef) =>
          this.dialog.open(UserResetMfaModalComponent, {
            data: user,
            disableClose: true,
            autoFocus: false
          })
        )
      ),
    { dispatch: false }
  );

  closeModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.UserResetMfaActionTypes.CLOSE_USER_RESET_MFA_DIALOG),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: UserResetMfaModalService,
    private snackbarService: G3SnackbarService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}
}
