import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiEndpointsUrl } from '@g3p/shared/constants/api-url.constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserResetMfaModalService {

  constructor(private httpClient: HttpClient) { }

  resetUserMfa(userId: string) {
    return this.httpClient
      .delete(
        environment.apiUrl +
        ApiEndpointsUrl.USERS.RESET_USER_MFA.replace('{id}', userId),
        { observe: 'response' }
      )
      .pipe(map((response: HttpResponse<any>) => { return response.status === 200 } ))
  }
}
