<div class="customer-details-container" g3pMasonryGrid>
    <div class="masonry-grid">
        <div class="grid-item">
            <g3-card data-automation="Cashbook - Admin page - Empty Customer Info Card"
                     class="grid-content empty-customer-card"
                     title="{{ 'content.customer-data' | translate }}"
                     *ngIf="customerDetails === null else customerDetailsTemplate"
            >
                <span>No customer data</span>
            </g3-card>

            <ng-template #customerDetailsTemplate>
                <g3-card data-automation="Cashbook - Admin page - Customer Info Card"
                         class="grid-content customer-card"
                         title="{{ 'content.customer-data' | translate }}"
                >
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card Name label">
                            {{ "content.name" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card name">
                            {{ customerDetails?.institutionName || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card street label">
                            {{ "content.street" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card street">
                            {{ customerDetails?.street || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card vatId label">
                            {{ "content.vatId" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card vatId">
                            {{ customerDetails?.vatId || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card fiscalCode label">
                            {{ "content.fiscalCode" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card fiscalCode">
                            {{ customerDetails?.fiscalCode || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card BSNR label">
                            {{ "content.company-number" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card BSNR">
                            {{ customerDetails?.locationNumber || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card postalCode label">
                            {{ "content.postal-code" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card postalCode">
                            {{ customerDetails?.postalCode || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card city label">
                            {{ "content.city" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card city">
                            {{ customerDetails?.city || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card email label">
                            {{ "content.email" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card email">
                            {{ customerDetails?.email || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card AIS name label">
                            {{ "content.ais-name" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card AIS name">
                            {{ customerDetails?.businessUnitName || '-' }}
                        </p>
                    </div>
                    <div class="wrapper">
                        <p class="item-description" data-automation="Cashbook - Admin page - Customer card AIS number label">
                            {{ "content.ais-customer-number" | translate }}
                        </p>
                        <p class="item-value" data-automation="Cashbook - Admin page - Customer card AIS number">
                            {{ customerDetails?.customerNumber || '-' }}
                        </p>
                    </div>
                </g3-card>
            </ng-template>
        </div>

        <div class="grid-item">
            <g3-card data-automation="Cashbook - Admin page - Empty Customer Workstations Card"
                     class="grid-content empty-customer-card"
                     title="{{ 'content.workstations' | translate }}"
                     *ngIf="!customerDetails?.workstations?.length else workStationTemplate"
            >
                <span>No workstations data</span>
            </g3-card>

            <ng-template #workStationTemplate>
                <g3-card data-automation="Cashbook - Admin page - Customer Workstations Card"
                         class="grid-content customer-card"
                         title="{{ 'content.workstations' | translate }}"
                >
                    <div class="container" *ngFor="let workstation of customerDetails.workstations">
                        <div class="wrapper ws">
                            <p class="item-description ws"
                               data-automation="Cashbook - Admin page - Customer card workstation label">
                                {{ "content.workstation-name" | translate }}
                            </p>
                            <p class="item-value ws"
                            data-automation="Cashbook - Admin page - Customer card workstation">
                                {{ workstation.code }}
                            </p>
                                <button
                                            mat-button
                                            color="primary"
                                            [disabled]="isToggleWorkstationActiveStatusButtonDisabled(workstation, customerDetails.workstations)"
                                            (click)="onToggleWorkstationActiveStatus(customerDetails.id, workstation)"
                                            data-automation="Cashbook - Admin page - Customer card toggle workstation active status"
                                            matTooltip="{{ toggleWorkstationActiveStatusButtonDisabledTooltip(workstation, customerDetails.workstations) | translate }}"
                                            [matTooltipDisabled]="!isToggleWorkstationActiveStatusButtonDisabled(workstation, customerDetails.workstations)">
                                        {{ (workstation.active ? 'content.deactivate' : 'content.activate') | translate }}
                                </button>
                        </div>
                    </div>
                </g3-card>
            </ng-template>
        </div>

        <div class="grid-item">
            <g3-card data-automation="Cashbook - Admin page - Empty Customer Accounts Card"
                     class="grid-content empty-customer-card"
                     title="{{ 'content.accounts' | translate }}"
                     *ngIf="!customerDetails?.users?.length else usersTemplate"
            >
                <span>No Accounts data</span>
            </g3-card>

            <ng-template #usersTemplate>
                <g3-card data-automation="Cashbook - Admin page - Customer Accounts Card"
                         class="grid-content customer-card-accounts"
                         title="{{ 'content.accounts' | translate }}"
                >
                    <div *ngFor="let user of customerDetails.users" class="container">
                        <div class="wrapper">
                            <p class="item-description" data-automation="Cashbook - Admin page - Customer card user-name label">
                                {{ "content.user-name" | translate }}
                            </p>
                            <p class="item-value" data-automation="Cashbook - Admin page - Customer card user-name">
                                {{ user.username }}
                            </p>
                        </div>
                        <div class="wrapper">
                            <p class="item-description" data-automation="Cashbook - Admin page - Customer card first-name label">
                                {{ "content.first-name" | translate }}
                            </p>
                            <p class="item-value" data-automation="Cashbook - Admin page - Customer card first-name">
                                {{ user.firstName }}
                            </p>
                        </div>
                        <div class="wrapper">
                            <p class="item-description" data-automation="Cashbook - Admin page - Customer card last-name label">
                                {{ "content.last-name" | translate }}
                            </p>
                            <p class="item-value" data-automation="Cashbook - Admin page - Customer card last-name">
                                {{ user.lastName }}
                            </p>
                        </div>
                        <div class="wrapper">
                            <p class="item-description" data-automation="Cashbook - Admin page - Customer card email label">
                                {{ "content.email" | translate }}
                            </p>
                            <p class="item-value" data-automation="Cashbook - Admin page - Customer card email">
                                {{ user.email }}
                            </p>
                        </div>
                        <div class="wrapper">
                            <p class="item-description" data-automation="Cashbook - Admin page - User active until label">
                                {{ "content.active-until" | translate }}
                            </p>
                            <p class="item-value" data-automation="Cashbook - Admin page - User end date value">
                                {{ user.endDate | g3Date }}
                            </p>
                        </div>
                        <div class="wrapper">
                            <p class="item-description" data-automation="Cashbook - Admin page - Active in use by customer label">
                                {{ "content.active-in-use-by" | translate }}
                            </p>
                            <div class="wrapper-user-customers">
                                <div class="row" *ngFor="let customer of user.customers">
                                    <p class="col" data-automation="Cashbook - Admin page - Customer name">
                                        {{ customer.name }}
                                    </p>
                                    <p class="col" data-automation="Cashbook - Admin page - End date value">
                                        {{ customer.endDate ? (customer.endDate | g3Date) : "-" }}
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div class="user-action-btns">
                            <button
                                (click)="openCustomerUserStatusModal(customerDetails ,user)"
                                class="customer-creation-back-btn action-btn"
                                color="primary"
                                data-automation="Cashbook - Admin page - Activate/Deactivate User"
                                mat-button
                            >
                                <span *ngIf="!isActive(user)">{{ "content.customer-user-activate-button" | translate }}</span>
                                <span *ngIf="isActive(user)">{{ "content.customer-user-deactivate-button" | translate }}</span>
                            </button>
                            <button
                                (click)="openUserResetMfaModal(user)"
                                class="customer-creation-back-btn action-btn"
                                color="primary"
                                data-automation="Cashbook - Admin page - User Reset MFA"
                                mat-button
                                [disabled]="!user.hasMfa || !isActive(user)"
                                matTooltip="{{ ( !isActive(user) ? 'content.user-not-active-reset-mfa-disabled-tooltip' :
                                    (user.hasMfa ? 'content.user-reset-mfa-enabled-tooltip' : 'content.user-reset-mfa-disabled-tooltip'))
                                    | translate }}"
                            >
                            <span>{{ "content.user-reset-mfa-button" | translate }}</span>
                        </button>
                        </div>
                    </div>
                </g3-card>
            </ng-template>
        </div>
    </div>
</div>
