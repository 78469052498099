import {
  CustomerDetailsResponseAttributes,
  CustomerDetailsResponseDto,
  CustomerUserResponse,
  WorkstationResponse
} from '../interfaces/customer-details-dto.interface';
import { CustomerDetailsDef, UserCustomerUsageDef } from '../interfaces/customer-details-def.interface';
import {
  AccountFormDef,
  BusinessUnitDef,
  BusinessUnitDto,
  BusinessUnitListDto,
  CustomerCreateFormDef,
  CustomerUserDef
} from '../interfaces/customer-def.interface';
import {
  CustomerRequestDto,
  CustomerUserDto,
  UserCustomerUsageResponse,
  UserResponse
} from '../interfaces/customer-dto.interface';
import { CustomerListResponse, CustomerListResponseDto } from '@g3p/customer/interfaces/customer-list-dto.interface';
import { CustomerListDef } from '@g3p/customer/interfaces/customer-list-def.interface';
import { WorkstationDef } from '@g3p/workstation/interfaces/workstation-def.interface';
import { WorkstationDto } from '@g3p/workstation/interfaces/workstation-dto.interface';
import {
  CustomerDeletionListResponseDto,
  CustomerDeletionStateListDef, CustomerDeletionStateListResponse
} from "@g3p/customer/interfaces/customer-deletion-state.interface";

export function mapCustomerDetailsResponseDto(response: CustomerDetailsResponseDto): CustomerDetailsDef {
  return mapCustomerDetailsResponse(response.data);
}

function mapCustomerDetailsResponse(data: CustomerDetailsResponseAttributes) {
  return {
    id: data.id,
    institutionName: data.attributes.institutionName,
    organizationName: data.attributes.organizationName,
    street: data.attributes.street,
    postalCode: data.attributes.postalCode,
    city: data.attributes.city,
    email: data.attributes.email,
    businessUnitName: data.attributes.businessUnitName,
    customerNumber: data.attributes.customerNumber,
    locationNumber: data.attributes.locationNumber,
    vatId: data.attributes.vatId,
    fiscalCode: data.attributes.fiscalCode,
    status: data.attributes.status,
    workstations: data.attributes.workstations.sort((l,r) => l.code.localeCompare(r.code)),
    users: data.attributes.users.map(user => mapCustomerUserResponse(user)),
    hasTse: data.attributes.hasTse,
    hasOpenTransactions: data.attributes.hasOpenTransactions
  }
}

function mapCustomerUserResponse(data: CustomerUserResponse) {
  return {
    id: data.id,
    username: data.attributes.username,
    firstName: data.attributes.firstName,
    lastName: data.attributes.lastName,
    email: data.attributes.email,
    endDate: data.attributes.endDate,
    hasMfa: data.attributes.hasMfa,
    customers: data.attributes.customers
  }
}

export function mapCustomerRequest(form: CustomerCreateFormDef): CustomerRequestDto {
  return {
    customerNumber: form.customerDataForm.customerNumber,
    locationNumber: form.customerDataForm.locationNumber,
    email: form.customerDataForm.email,
    institutionName: form.customerDataForm.institutionName,
    organizationName: form.customerDataForm.organizationName,
    businessUnitId: form.customerDataForm.businessUnitId,
    city: form.customerDataForm.city,
    postalCode: form.customerDataForm.postalCode,
    street: form.customerDataForm.street,
    vatId: form.customerDataForm.vatId,
    fiscalCode: form.customerDataForm.fiscalCode,
    workstations: form.workstationForm.workstations.map(workstation => mapWorkstationRequest(workstation)),
    users: form.accountForm.map(user => mapUserRequest(user))
  }
}

export function mapUserRequest(form: AccountFormDef): CustomerUserDto {
  if ( form.id ) {
    if (form.initialPassword) {
      return {
        id: form.id,
        username: form.username,
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        endDate: form.endDate,
        initialPassword: form.initialPassword
      }
    } else {
      return {
        id: form.id,
        username: form.username,
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        endDate: form.endDate,
      }
    }
  } else {
    return {
      username: form.username,
      email: form.email,
      firstName: form.firstName,
      lastName: form.lastName,
      endDate: form.endDate,
      initialPassword: form.initialPassword
    }
  }
}

export function mapWorkstationRequest(workstation: WorkstationDef): WorkstationDto {
  return {
    id: workstation.id,
    code: workstation.code,
    active: workstation.active,
    closureStatus: workstation.closureStatus,
    deletable: workstation.deletable
  }
}

export function mapCustomerForm(customer: CustomerDetailsDef): CustomerCreateFormDef {
  return {
    customerDataForm: {
      id: customer.id,
      institutionName: customer.institutionName,
      organizationName: customer.organizationName,
      businessUnitId: '',
      businessUnitName: customer.businessUnitName,
      street: customer.street,
      city: customer.city,
      email: customer.email,
      postalCode: customer.postalCode,
      customerNumber: customer.customerNumber,
      vatId: customer.vatId,
      locationNumber: customer.locationNumber,
      fiscalCode: customer.fiscalCode
    },
    workstationForm: {
      workstations: customer.workstations.map(workstation => mapWorkstationForm(workstation))
    },
    accountForm: customer.users.map(user => mapUserForm(user))
  }
}

function mapWorkstationForm(workstation: WorkstationResponse): WorkstationDef{
  return {
    id: workstation.id,
    code: workstation.code,
    active: workstation.active,
    closureStatus: workstation.closureStatus,
    deletable: workstation.deletable
  }
}

function mapUserForm(user: CustomerUserDef): AccountFormDef {
  return {
    id: user.id,
    username: user.username,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    endDate: user.endDate,
    initialPassword: null
  }
}

export function mapBusinessUnitList(response: BusinessUnitListDto): BusinessUnitDef[] {
  return response.data.map(data => mapBusinessUnitData(data));
}

function mapBusinessUnitData(data: BusinessUnitDto): BusinessUnitDef {
  return {
    id: data.id,
    name: data.name
  } as BusinessUnitDef;
}

export function mapCustomerListResponseDto(response: CustomerListResponseDto): CustomerListDef[] {
  return response.data.map(data => mapCustomerListResponse(data)).sort((l,r) => l.institutionName.localeCompare(r.institutionName));
}

function mapCustomerListResponse(data: CustomerListResponse): CustomerListDef {
  return {
    id: data.id,
    institutionName: data.attributes.institutionName,
    organizationName: data.attributes.organizationName,
    city: data.attributes.city,
    businessUnitName: data.attributes.businessUnitName,
    customerNumber: data.attributes.customerNumber,
    status: data.attributes.status
  };
}

export function mapUserResponse(response: UserResponse): CustomerUserDef {
  return {
    id: response.id,
    username: response.attributes.username,
    firstName: response.attributes.firstName,
    lastName: response.attributes.lastName,
    email: response.attributes.email,
    endDate: response.attributes.endDate
  };
}

export function mapUserCustomerUsageResponse(response: UserCustomerUsageResponse): UserCustomerUsageDef {
  return {
    customerUserId: response.attributes.customerUserUsage.customerUserId,
    name: response.attributes.customerUserUsage.name,
    endDate: response.attributes.customerUserUsage.endDate,
    active: response.attributes.customerUserUsage.active
  }
}

export function mapCustomerDeletionStateListDto(response: CustomerDeletionListResponseDto): CustomerDeletionStateListDef[] {
  return response.data.map(data => mapCustomerDeletionStateListResponse(data));
}

export function mapCustomerDeletionStateListResponse(data: CustomerDeletionStateListResponse): CustomerDeletionStateListDef {
  return {
    customerId: data.id,
    customerName: data.attributes.customerName,
    message: data.attributes.message,
    status: data.attributes.status,
    startDate: data.attributes.startDate,
    documentId: data.attributes.documentId
  }
}
