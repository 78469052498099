<g3-modal-regular>
    <g3-modal-title>
        <span>{{ "content.user-reset-mfa-title" | translate }}</span>
    </g3-modal-title>

    <g3-modal-body>
        <span>{{ message }}</span>
    </g3-modal-body>
    <g3-modal-footer>
        <button
            mat-button
            color="primary"
            data-automation="Cashbook - Admin page - Cancel user deactivation"
            (click)="closeDialog()"
        >{{ 'content.cancel' | translate }}</button>
        <button
            #confirmButton
            mat-button
            color="primary"
            data-automation="Cashbook - Admin page - Confirm user deactivation"
            (click)="confirmDialog()"
        >{{ 'content.confirm' | translate }}</button>
    </g3-modal-footer>
</g3-modal-regular>